import { UntypedFormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { I18NextPipe } from 'angular-i18next';

import { environment } from '../../environments/environment';

// Material sort comparators
export const dateSortComparator = (a_date, b_date, direction): number => {
    a_date = moment(a_date);
    b_date = moment(b_date);

    if (a_date.isBefore(b_date)) return direction === 'desc' ? -1 : 1;
    if (b_date.isBefore(a_date)) return direction === 'asc' ? -1 : 1;

    return 0;
};

export const alphabetSortComparator = (
    a_string,
    b_string,
    direction,
): number => {
    a_string = a_string.toLowerCase().trim();
    b_string = b_string.toLowerCase().trim();

    if (a_string > b_string) return direction === 'desc' ? -1 : 1;
    if (a_string < b_string) return direction === 'asc' ? -1 : 1;

    return 0;
};

export const numberSortComparator = (a, b, direction): number => {
    a = parseFloat(a);
    b = parseFloat(b);

    if (a > b) return direction === 'desc' ? -1 : 1;
    if (a < b) return direction === 'asc' ? -1 : 1;

    return 0;
};

export const compare = (
    a: number | string,
    b: number | string,
    isAsc: boolean,
): number => {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
};

export const disableStatus = (
    values: Array<{ condition: boolean; label: string }>,
): {
    disabled: boolean;
    statuses: string[];
    label: string;
} => {
    const status = {
        disabled: false,
        statuses: [],
        label: '',
    };

    status.statuses = values.reduce((acc, value) => {
        if (value.condition) {
            status.disabled = true;
            acc.push(value.label);
        }

        return acc;
    }, []);

    status.label = status.statuses.join('\n');

    delete status.statuses;

    return status;
};

export const getImageUrl = (value?: { image: any; size?: string }): string => {
    const base = environment.apiUrl;
    const userpic = `${base}/uploads/images/userpic.jpg`;

    if (!value) {
        return userpic;
    }

    const { image, size } = value;

    if (
        !environment.production ||
        !image ||
        !image.filename ||
        !image.destination ||
        size === 'userpic'
    ) {
        return userpic;
    }

    let url = `${base}/${image.destination}/${image.filename}`;

    if (size) {
        url += `?width=${size}&height=${size}`;
    }

    return url;
};

export const translatePaginatorIntl = (
    paginator: MatPaginator,
    i18next: I18NextPipe,
): void => {
    paginator._intl.itemsPerPageLabel = `${i18next.transform(
        'general:items_page_label',
    )}:`;

    paginator._intl.nextPageLabel = `${i18next.transform(
        'general:paginator_next_page',
    )}:`;

    paginator._intl.previousPageLabel = `${i18next.transform(
        'general:paginator_previous_page',
    )}:`;

    paginator._intl.firstPageLabel = `${i18next.transform(
        'general:paginator_first_page',
    )}:`;

    paginator._intl.lastPageLabel = `${i18next.transform(
        'general:paginator_last_page',
    )}:`;
};

export const payment_types: {
    [type: string]: {
        label: string;
        value: string;
        online_payment?: boolean;
        not_in_cashbox?: boolean;
        for_fop?: boolean;
        site_payments?: boolean;
    };
} = {
    cash: {
        value: 'cash',
        label: 'payment_type_cash',
        for_fop: true,
    },
    fondy: {
        value: 'fondy',
        label: 'payment_type_fondy',
        online_payment: true,
        for_fop: true,
    },
    liqpay: {
        value: 'liqpay',
        label: 'payment_type_liqpay',
        online_payment: true,
        for_fop: true,
        site_payments: true,
    },
    monobank: {
        value: 'monobank',
        label: 'payment_type_monobank',
        online_payment: true,
        for_fop: true,
        site_payments: true,
    },
    card: {
        value: 'card',
        label: 'payment_type_card',
    },
    to_card: {
        value: 'to_card',
        label: 'payment_type_to_card',
    },
    legal: {
        value: 'legal',
        label: 'payment_type_legal',
    },
    bank: {
        value: 'bank',
        label: 'payment_type_bank',
    },
    ibox: {
        value: 'ibox',
        label: 'payment_type_ibox',
    },
    user_balance: {
        value: 'user_balance',
        label: 'payment_type_user_balance',
        not_in_cashbox: true,
    },
    freeze_transfer: {
        value: 'freeze_transfer',
        label: 'payment_type_freeze_transfer',
        not_in_cashbox: true,
    },
    privatbank_payparts: {
        value: 'privatbank_payparts',
        label: 'payment_type_privatbank_payparts',
        online_payment: true,
        for_fop: true,
    },
    monobank_payparts: {
        value: 'monobank_payparts',
        label: 'payment_type_monobank_payparts',
        online_payment: true,
        for_fop: true,
    },
    oshadbank_payparts: {
        value: 'oshadbank_payparts',
        label: 'payment_type_oshadbank_payparts',
        for_fop: true,
    },
    pumb_payparts: {
        value: 'pumb_payparts',
        label: 'payment_type_pumb_payparts',
        for_fop: true,
    },
};

export const USER_BALANCES = [
    {
        label: 'general:account_balance_money',
        amount_label: 'general:account_balance_money_amount',
        value: 'money',
    },
    {
        label: 'general:account_balance_bonus',
        amount_label: 'general:account_balance_bonus_amount',
        value: 'bonus',
    },
    {
        label: 'general:account_balance_referral',
        amount_label: 'general:account_balance_referral_amount',
        value: 'referral',
    },
];

export const getCashboxPaymentTypes = (): Array<{
    value: string;
    label: string;
}> =>
    Object.values(payment_types).filter(
        (payment_type) => !payment_type.not_in_cashbox,
    );

export const isCashboxPaymentType = (payment_type: string): boolean =>
    !payment_type || !payment_types[payment_type]?.not_in_cashbox;

export const getOnlinePaymentTypes = (): Array<{
    value: string;
    label: string;
}> =>
    Object.values(payment_types).filter(
        (payment_type) => payment_type.online_payment,
    );

export const isOnlinePaymentType = (payment_type: string): boolean =>
    payment_type && payment_types[payment_type]?.online_payment;

export const getFopPaymentTypes = (): Array<{
    value: string;
    label: string;
}> =>
    Object.values(payment_types).filter((payment_type) => payment_type.for_fop);

export const getSitePaymentTypes = (): Array<{
    value: string;
    label: string;
}> =>
    Object.values(payment_types).filter(
        (payment_type) => payment_type.site_payments,
    );

export const coach_payment_types: {
    [type: string]: {
        label: string;
        value: string;
    };
} = {
    rating: {
        value: 'rating',
        label: 'coach_payment_operation_type_rating',
    },
    homework: {
        value: 'homework',
        label: 'coach_payment_operation_type_homework',
    },
};

export const refund_payment_types: {
    [type: string]: {
        label: string;
        value: string;
        auto?: boolean;
    };
} = {
    cash: {
        value: 'cash',
        label: 'refund_payment_type_cash',
    },
    bank: {
        value: 'bank',
        label: 'refund_payment_type_bank',
    },
    fondy: {
        value: 'fondy',
        label: 'refund_payment_type_fondy',
        auto: true,
    },
    liqpay: {
        value: 'liqpay',
        label: 'refund_payment_type_liqpay',
        auto: true,
    },
    monobank: {
        value: 'monobank',
        label: 'refund_payment_type_monobank',
        auto: true,
    },
    privatbank_payparts: {
        value: 'privatbank_payparts',
        label: 'payment_type_privatbank_payparts',
        auto: true,
    },
    monobank_payparts: {
        value: 'monobank_payparts',
        label: 'payment_type_monobank_payparts',
        auto: true,
    },
    pumb_payparts: {
        value: 'pumb_payparts',
        label: 'payment_type_pumb_payparts',
        auto: true,
    },
    user_balance: {
        value: 'user_balance',
        label: 'payment_type_user_balance',
    },
};

export const getRefundPaymentTypes = (): Array<{
    value: string;
    label: string;
}> =>
    Object.values(refund_payment_types).filter(
        (payment_type) => !payment_type.auto,
    );

export const isAutoRefundPaymentType = (payment_type: string): boolean =>
    !!payment_type && !!refund_payment_types[payment_type]?.auto;

export const payment_amount_limits = {
    add: {
        min: 1,
        max: 99999,
    },
    edit: {
        max: 99999,
        min: -99999,
    },
};

export const payment_statuses = {
    approved: {
        label: 'payment_status_approved',
        css_class: { approved: true },
    },
    waiting: {
        label: 'payment_status_waiting',
        css_class: { waiting: true },
    },
    rejected: {
        label: 'payment_status_rejected',
        css_class: { rejected: true },
    },
};

export const refund_statuses = {
    created: {
        label: 'refund_status_created',
        css_class: { created: true },
    },
    started: {
        label: 'refund_status_started',
        css_class: { started: true },
    },
    filled: {
        label: 'refund_status_filled',
        css_class: { filled: true },
    },
    complete: {
        label: 'refund_status_complete',
        css_class: { complete: true },
    },
};

export const payers = {
    individual: {
        value: 'individual',
        label: 'payers_individual',
    },
    legal: {
        value: 'legal',
        label: 'payers_legal',
    },
};

export const ages = {
    adult: {
        value: 'adult',
        label: 'ages_adult',
    },
    minor: {
        value: 'minor',
        label: 'ages_minor',
        icon: 'child_care',
    },
};

export const studying_forms = [
    {
        value: false,
        label: 'studying_forms_offline',
    },
    {
        value: true,
        label: 'studying_forms_online',
    },
];

const appUrlValidation = (
    control: UntypedFormControl,
): { [key: string]: boolean } | null => {
    try {
        if (!control.value) return null;

        const url = new URL(control.value);

        if (url) return null;
    } catch {
        return { url: true };
    }
};

export const appValidators: Readonly<{
    readonly [key: string]: (
        control: UntypedFormControl,
    ) => { [key: string]: boolean } | null;
}> = {
    url: appUrlValidation,
};

export const downloadFile = (file_id, signed = false): string => {
    return `${environment.apiUrl}/api/files/${file_id}${signed ? '/signed' : ''}`;
};

export const socialValidators = {
    linkedin: new RegExp(
        '^https?://((www|\\w\\w)\\.)?linkedin.com/((in/[^/]+/?)|(pub/[^/]+/((\\w|\\d)+/?){3}))$',
    ),
    telegram: new RegExp('^(?!https?://|www\\.|t\\.me/)([\\w_]+)$'),
    facebook: new RegExp(
        '(?:(?:http|https):\\/\\/)?(?:www.)?facebook.com\\/(?:(?:\\w)*#!\\/)?(?:pages\\/)?(?:[?\\w\\-]*\\/)?(?:profile.php\\?id=(?=\\d.*))?([\\w\\-]*)?',
    ),
    instagram: new RegExp(
        '(?:(?:http|https):\\/\\/)?(?:www.)?(?:instagram.com|instagr.am)\\/([A-Za-z0-9-_]+)',
    ),
    url: new RegExp(
        "^(?:http(s)?:\\/\\/)?[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&'\\(\\)\\*\\+,;=.]+$",
    ),
};

export const fieldValidators = {
    ringostat_id: new RegExp("^[\\w'?@&#.]{0,}$"),
};

export enum ROLES {
    user = 'role_user',
    coach = 'role_coach',
    student = 'role_student',
    mentor = 'role_mentor',
    visitor = 'role_visitor',
}

export const GROUP_ROLES = {
    coach: {
        label: 'group_role_coach',
        value: 'coach',
    },
    mentor: {
        label: 'group_role_mentor',
        value: 'mentor',
    },
    visitor: {
        label: 'group_role_visitor',
        value: 'visitor',
    },
    student: {
        label: 'group_role_student',
        value: 'student',
    },
};

export const UPDATE_ROLES = {
    coach: 'group_role_coach',
    student: 'group_role_student',
};

export const BUDGETS_PERMISSIONS_BY_PATH = {
    plan: 'groups_budget',
    categories: 'categories_budget',
    salary: 'salaries_budget',
};

export const SALARY_PAYMENT_WAYS = [
    {
        label: 'payment_way_cash',
        value: 'cash',
    },
    {
        label: 'payment_way_fop',
        value: 'fop',
    },
    {
        label: 'payment_way_gig_contract',
        value: 'gig_contract',
    },
    {
        label: 'payment_way_civil_contract',
        value: 'civil_contract',
    },
];

export const encodeSpecialChars = (value: string): string => {
    const regexp = /[&+=#?]/g;

    return value.replace(regexp, (char) => {
        return encodeURIComponent(char);
    });
};

export const PERIOD_MAP = {
    all_time: null,
    one_year: { type: 'year', value: 1 },
    six_months: { type: 'months', value: 6 },
    three_months: { type: 'months', value: 6 },
    one_month: { type: 'month', value: 1 },
    one_week: { type: 'week', value: 1 },
    one_day: { type: 'day', value: 1 },
};

export const PERIODS = [
    'all_time',
    'one_year',
    'six_months',
    'three_months',
    'one_month',
    'one_week',
    'one_day',
];

/**
 * @param period - required value from form
 * @param now - required moment();
 * @param period_config - default value PERIOD_MAP { @see PERIOD_MAP }
 * @returns { date_from: string, date_to: string }
 */
export const getDatePeriod = (
    period: string,
    now: moment.Moment,
    period_config = PERIOD_MAP,
): {
    date_from: string;
    date_to: string;
} => {
    const config = period_config[period];

    let date_from = '';
    let date_to = '';

    if (config) {
        date_from = now
            .clone()
            .subtract(config.type, config.value)
            .format('YYYY-MM-DD[T00:00:00.000Z]');

        date_to = now.clone().format('YYYY-MM-DD[T23:59:59.999Z]');
    }

    return { date_from, date_to };
};

export enum EDRPOU {
    MIN = 8,
    MAX = 10,
}

export const mongo_id_regex = new RegExp(
    /^(?=[a-f\d]{24}$)(\d+[a-f]|[a-f]+\d)/,
);

export const english_levels = [
    {
        value: 'elementary',
        label: 'Elementary',
    },
    {
        value: 'pre-intermediate',
        label: 'Pre-Intermediate',
    },
    {
        value: 'intermediate',
        label: 'Intermediate',
    },
    {
        value: 'upper',
        label: 'Upper-Intermediate',
    },
    {
        value: 'advanced',
        label: 'Advanced',
    },
];

export enum VideoPlayers {
    YOUTUBE = 'youtube',
    VIMEO = 'vimeo',
}

export const preventSort = () => 0;
