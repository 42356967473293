import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { HttpService } from '@services/http/http.service';
import { GlobalService } from '../global/global.service';
import { UserFilters } from '../../constants/constants';

import { PosthogService } from '@services/posthog/posthog.service';
import { encodeSpecialChars } from '@shared/common';

@Injectable()
export class UserService {
    public user_profile$: Subject<any> = new Subject<any>();

    private _current_profile: any;

    public set current_profile(value) {
        if (value) {
            if (Array.isArray(value.roles)) {
                value.roles.forEach((role) => (value[`is_${role}`] = true));
            }

            if (value.group_roles) {
                Object.keys(value.group_roles).forEach(
                    (group) =>
                        (value[`is_${value.group_roles[group].role}`] = true),
                );
            }

            if (value.is_mentor || value.is_visitor) {
                value.is_coach = true;
            }

            if (value.is_user) {
                value.is_student = true;
            }
        }

        this._current_profile = value;
        this.user_profile$.next(this._current_profile);
    }

    public get current_profile(): any {
        return this._current_profile;
    }

    constructor(
        private http: HttpService,
        public globalService: GlobalService,
        private posthogService: PosthogService,
    ) {}

    public addUser(value): Observable<any> {
        return this.http.post(`/api/users`, value);
    }

    public updateUser(id: any, value: any): Observable<any> {
        return this.http.put(`/api/users/${id}`, value);
    }

    public getUser(user_id: string): Observable<any> {
        return this.http.get(`/api/users/${user_id}`);
    }

    public deleteUser(user_id: string): Observable<any> {
        return this.http.delete(`/api/users/${user_id}`);
    }

    public login(value): Observable<any> {
        return this.http.post(`/api/users/login`, value);
    }

    public logout(): Observable<any> {
        return this.http.get(`/api/users/logout`).pipe(
            tap(({ success }) => {
                if (success) this.posthogService.resetUser();
            }),
        );
    }

    public getMyData(): Observable<any> {
        return this.http.get(`/api/users/mydata`);
    }

    public getAllCoaches({
        courses,
        schools,
        limit,
        skip,
        inactive_coaching,
        roles,
        sort,
        direction,
    }: {
        skip: number;
        limit: number;
        direction: number;
        schools?: string[];
        courses?: string[];
        roles?: Array<'coach' | 'mentor' | 'visitor'>;
        inactive_coaching?: Array<'yes' | 'no'>;
        sort?: 'end_coaching_date';
    }): Observable<any> {
        let params = new HttpParams({
            fromObject: {
                'courses[]': courses || [],
                'schools[]': schools || [],
                'roles[]': roles || [],
                'inactive_coaching[]': inactive_coaching || [],
                limit,
                skip,
                direction,
            },
        });

        if (sort) params = params.append('sort', `${sort}`);

        return this.http.get(`/api/users/coaches`, { params });
    }

    public getUsers(select = false): Observable<any> {
        let params = new HttpParams();

        if (select) {
            params = params.append('select', `${select}`);
        }

        return this.http.get(`/api/users`, { params });
    }

    public addContact(user_id: any, value: any): Observable<any> {
        return this.http.post(`/api/users/${user_id}/contacts`, value);
    }

    public addSchedule(user_id: any, value: any): Observable<any> {
        return this.http.post(`/api/users/${user_id}/schedule`, value);
    }

    public updateVacations(user_id: any, value: any): Observable<any> {
        return this.http.put(`/api/users/${user_id}/vacations`, value);
    }

    public updateContacts(user_id: any, value: any): Observable<any> {
        return this.http.put(`/api/users/${user_id}/contacts`, value);
    }

    public updateUserBirthdayPresent(
        user_id: string,
        present: boolean,
    ): Observable<any> {
        return this.http.post(`/api/users/${user_id}/birthdaypresent`, {
            present,
        });
    }

    public checkUser(user_id: string): Observable<any> {
        return this.http.get(`/api/users/${user_id}/check`);
    }

    public searchUsers(search: string): Observable<any> {
        return this.http.get(
            `/api/users/search?search=${encodeURIComponent(search)}`,
        );
    }

    public changePassword(value: {
        new: string;
        current: string;
        confirm: string;
    }): Observable<any> {
        return this.http.put(`/api/users/password`, value);
    }

    public sendLMSAccessStudent(user_id: string): Observable<any> {
        return this.http.post(
            `/api/users/${user_id}/send_lms_access_student`,
            {},
        );
    }

    public sendLMSAccessCoach(user_id: string, group_id: any): Observable<any> {
        return this.http.post(
            `/api/users/${user_id}/send_lms_access_coach`,
            group_id,
        );
    }

    public getAccountProfile(user_id: string): Observable<any> {
        return this.http.get(`/api/users/accounts/${user_id}/information`);
    }

    public getAccountProfileEdit(user_id: string): Observable<any> {
        return this.http.get(`/api/users/accounts/${user_id}/edit`);
    }

    public updateAccountProfile(user_id: string, value: any): Observable<any> {
        return this.http.put(`/api/users/accounts/${user_id}/edit`, value);
    }

    public getAccountFeedbacks(user_id: string): Observable<any> {
        return this.http.get(`/api/users/accounts/${user_id}/feedbacks`);
    }

    public getAccountPayments(user_id: string): Observable<any> {
        return this.http.get(`/api/users/accounts/${user_id}/payments`);
    }

    public getCoachPayments(user_id: string): Observable<any> {
        return this.http.get(`/api/users/accounts/${user_id}/coach-payments`);
    }

    public getAccountCVs(user_id: string): Observable<any> {
        return this.http.get(`/api/users/accounts/${user_id}/cvs`);
    }

    public getAccountGroups(user_id: string): Observable<any> {
        return this.http.get(`/api/users/accounts/${user_id}/groups`);
    }

    public getAccountEmploymentGroups(user_id: string): Observable<any> {
        return this.http.get(
            `/api/users/accounts/${user_id}/employment/groups`,
        );
    }

    public getAccountGroupsStats(user_id: string): Observable<any> {
        return this.http.get(`/api/users/accounts/${user_id}/groups/stats`);
    }

    public getAccountEmployments(user_id: string): Observable<any> {
        return this.http.get(`/api/users/accounts/${user_id}/employments`);
    }

    public addAccountEmployment(user_id: string, value: any): Observable<any> {
        return this.http.post(
            `/api/users/accounts/${user_id}/employments`,
            value,
        );
    }

    public deleteAccountEmployment(
        user_id: string,
        employment_id: string,
    ): Observable<any> {
        return this.http.delete(
            `/api/users/accounts/${user_id}/employments/${employment_id}`,
        );
    }

    public getProfileParams(user_id: string, role: string): Observable<any> {
        return this.http.get(`/api/users/${user_id}/${role}/profile/params`);
    }

    public getEmployeeProfile(profile_id: string): Observable<any> {
        return this.http.get(`/api/users/${profile_id}/profile`);
    }

    public getEmployeeInformation(profile_id: string): Observable<any> {
        return this.http.get(`/api/users/${profile_id}/profile/information`);
    }

    public restorePassword(value: string): Observable<any> {
        return this.http.post(`/api/users/restore-password`, { email: value });
    }

    public setPassword(token, value: { email: string }): Observable<any> {
        return this.http.put(`/api/users/restore-password/${token}`, value);
    }

    public getUserNotes(user_id: string): Observable<any> {
        return this.http.get(`/api/users/accounts/${user_id}/notes`);
    }

    public getUserCalls(user_id: string): Observable<any> {
        return this.http.get(`/api/ringostat/history/user/${user_id}/`);
    }

    public addNoteToUser(
        user_id: string,
        value: { note: string; files: string[] },
    ): Observable<any> {
        return this.http.post(`/api/users/${user_id}/notes`, value);
    }

    public getAdmins(school_id: string): Observable<any> {
        return this.http.get(`/api/users/school/${school_id}/admins`);
    }

    public getAllAdmins(): Observable<any> {
        return this.http.get('/api/users/managers');
    }

    public searchCompareUsers(search: string): Observable<any> {
        return this.http.get(
            `/api/users/compare/search?search=${encodeURIComponent(search)}`,
        );
    }

    public compareUsersValidationCode(
        user_id: string,
        code: string,
    ): Observable<any> {
        return this.http.post(`/api/users/${user_id}/compare/code/check`, {
            code,
        });
    }

    public sentVerificationMergeCode(
        user_id: string,
        direction: 'from' | 'to',
    ): Observable<any> {
        return this.http.post(`/api/users/${user_id}/compare/code/send`, {
            direction,
        });
    }

    public compareMergeUsers(from: string, to: string): Observable<any> {
        return this.http.get('/api/users/compare', {
            params: new HttpParams({ fromObject: { from, to } }),
        });
    }

    public replaceAccounts(value: any): Observable<any> {
        return this.http.post(`/api/users/replace`, value);
    }

    public searchEmployees(query: string): Observable<any> {
        return this.http.get(
            `/api/users/search/employees?search=${encodeURIComponent(query)}`,
        );
    }

    public getStudentRefunds(data): Observable<any> {
        return this.http.get(`/api/refunds/student/${data.student_id}`);
    }

    public getUserFilters(filter_name: string): Observable<any> {
        const params = new HttpParams({
            fromObject: {
                filter_name,
            },
        });

        return this.http.get('/api/users/mydata/filters', { params });
    }

    public setUserFilters(value: {
        filter_name: UserFilters;
        filter_value: any;
    }): Observable<any> {
        return this.http.post('/api/users/mydata/filters', value);
    }

    public getProfileFines(user_id: string): Observable<any> {
        return this.http.get(`/api/tasks/fines/${user_id}`);
    }

    public updateProfileFines(
        user_id: string,
        value: { status: 'payed' | 'deleted'; fines: string[] },
    ): Observable<any> {
        return this.http.put(`/api/tasks/fines/${user_id}`, value);
    }

    public getCoachStars(value: {
        skip: number;
        limit: number;
        direction?: number;
        sort?: string;
        user_id: string;
    }): Observable<any> {
        let params = new HttpParams({
            fromObject: {
                skip: value.skip,
                limit: value.limit,
            },
        });

        if (value.direction)
            params = params.append('direction', value.direction);
        if (value.sort) params = params.append('sort', value.sort);

        return this.http.get(`/api/users/stars/${value.user_id}`, { params });
    }

    public getUserEmployments(value: {
        skip: number;
        limit: number;
        rejected: boolean;
    }): Observable<any> {
        return this.http.get('/api/user-employments', {
            params: new HttpParams({
                fromObject: value,
            }),
        });
    }

    public approveUserEmployments(value: {
        _id: string;
        group: string;
        company: string;
    }): Observable<any> {
        return this.http.post('/api/user-employments/approve', value);
    }

    public rejectUserEmployments(_id: string): Observable<any> {
        return this.http.put('/api/user-employments/reject', {
            _id,
        });
    }

    public addUserNavigationItem(value): Observable<any> {
        return this.http.post(`/api/user-navigations`, value);
    }

    public getUserNavigation(): Observable<any> {
        return this.http.get('/api/user-navigations');
    }

    public updateUserNavigation(value: any): Observable<any> {
        return this.http.put('/api/user-navigations', value);
    }

    public getStudentsByStatus({
        direction,
        sort,
        skip,
        limit,
        status,
        courses,
        freeze_reasons,
    }: {
        skip: number;
        limit: number;
        status: string;
        direction?: 1 | -1;
        sort?: 'freeze_date' | 'unfreeze_payment';
        courses?: string[];
        freeze_reasons?: string[];
    }): Observable<any> {
        const params: any = {
            skip,
            limit,
            status,
            'courses[]': courses || [],
            'freeze_reasons[]': freeze_reasons || [],
        };

        if (sort) params.sort = sort;
        if (direction === 1 || direction === -1) params.direction = direction;

        return this.http.get(`/api/users/students/status`, {
            params: new HttpParams({
                fromObject: params,
            }),
        });
    }

    public getAccountSprints(user_id: string): Observable<any> {
        return this.http.get(`/api/sprints/user/${user_id}`);
    }

    public unlinkUserTelegram(user_id: string): Observable<any> {
        return this.http.post(
            `/api/users/accounts/${user_id}/unlink-telegram`,
            {},
        );
    }

    public getTelegramHistory(user_id: string): Observable<any> {
        return this.http.get(`/api/users/accounts/${user_id}/telegram-history`);
    }

    public getUserSupportChat(user_id: string): Observable<any> {
        return this.http.get(`/api/support/chats/users/${user_id}`);
    }

    public getDebtors(query: {
        skip: number;
        limit: number;
        search?: string;
        no_manager: boolean;
        courses?: string;
        managers?: string;
        sort?: string;
        direction?: 'asc' | 'desc';
    }): Observable<any> {
        let params = new HttpParams({
            fromObject: {
                skip: query.skip,
                limit: query.limit,
                no_manager: query.no_manager,
                'courses[]': query.courses?.length ? query.courses : [],
                'managers[]': query.managers?.length ? query.managers : [],
            },
        });

        if (query.search) {
            params = params.set('search', query.search);
        }

        if (query.sort) {
            params = params.set('sort', query.sort);
        }

        if (query.direction) {
            const dir = query.direction === 'asc' ? '1' : '-1';

            params = params.set('direction', dir);
        }

        return this.http.get('/api/users/students/debtors', { params });
    }

    public getStudentsCheckList(query: {
        skip: number;
        limit: number;
        search?: string;
        start_date?: string;
        end_date?: string;
        created_from?: string;
        created_to?: string;
        courses?: string[];
        linkedin: boolean;
        cv_video: boolean;
        checked_cv: boolean;
        employment_video: boolean;
        interview: boolean;
        employment: boolean;
        sort?: string;
        direction?: 'asc' | 'desc';
    }): Observable<any> {
        let params = new HttpParams({
            fromObject: {
                skip: query.skip,
                limit: query.limit,
                'courses[]': query.courses || [],
                linkedin: query.linkedin || false,
                cv_video: query.cv_video || false,
                checked_cv: query.checked_cv || false,
                employment_video: query.employment_video || false,
                interview: query.interview || false,
                employment: query.employment || false,
            },
        });

        if (query.search) {
            params = params.set('search', query.search);
        }

        if (query.start_date && query.end_date) {
            params = params.set('updated_from', query.start_date);
            params = params.set('updated_to', query.end_date);
        }

        if (query.created_from && query.created_to) {
            params = params.set('created_from', query.created_from);
            params = params.set('created_to', query.created_to);
        }

        if (query.sort) {
            params = params.set('sort', query.sort);
        }

        if (query.direction) {
            const dir = query.direction === 'asc' ? '1' : '-1';

            params = params.set('direction', dir);
        }

        return this.http.get('/api/user-employments/checklists', { params });
    }

    public getAllHRs(value?: { skip: number; limit: number }): Observable<any> {
        let params = new HttpParams();

        if (value) {
            params = params.append('skip', `${value.skip}`);
            params = params.append('limit', `${value.limit}`);
        }

        return this.http.get('/api/users/accounts/hrs', { params });
    }

    public findHillelUsers(query: {
        search?: string;
        roles?: string[];
        school?: string;
        group?: string;
        users?: string[];
        select?: boolean;
    }): Observable<any> {
        let params = new HttpParams({
            fromObject: {
                'roles[]': query.roles || [],
                'users[]': query.users || [],
                select: !!query.select,
            },
        });

        if (query.school) {
            params = params.append('school', query.school);
        }

        if (query.group) {
            params = params.append('group', query.group);
        }

        let url = '/api/users/search/find';

        if (query.search) {
            const encoded = encodeSpecialChars(query.search);
            url += `?search=${encoded}`;
        }

        return this.http
            .get(url, { params })
            .pipe(catchError(() => of({ success: false })));
    }
}
